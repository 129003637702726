// Retrieve variables for viewport sizes, which more accurately match values used in CSS media queries
// http://andylangton.co.uk/blog/development/get-viewport-size-width-and-height-javascript

// use x or y instead of y from now on

function viewport() {

	var w = window,
		d = document,
		e = d.documentElement,
		g = d.getElementsByTagName('body')[0];
		
	x = w.innerWidth||e.clientWidth||g.clientWidth,
	y = w.innerHeight||e.clientHeight||g.clientHeight;
		
}

viewport();

$(window).resize( function() {
	viewport();
});

break1	= 700;