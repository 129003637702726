form	= $('#enquiry');

var options = { 
    beforeSubmit:  processing,
    success:       done
};

form.submit(function (event) {

	event.preventDefault();

	console.log(event);
	
	$(this).ajaxSubmit(options);
	
});

function onSubmit(token) {
	console.log(token);
	form.ajaxSubmit(options);
}

function processing(formData, jqForm, options) { 
    
//	console.log('processing');
 	
 	$('body').addClass('processingForm');
	form.addClass('processing');
	return true;
	
} 
 
function done(responseText, statusText, xhr, $form)  {

	console.log('done');
	
	console.log(responseText);
	
	$('body').removeClass('processingForm');
	form.removeClass('processing');
	
	if( responseText.length > 0 ) {
		
		// Error
		console.log('error');
		
		form.addClass('error');
		form.find('.errorMessage').html(responseText);
		
	} else {
		
		form.addClass('success');
		form.removeClass('error');
		
	}
	
}